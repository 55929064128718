<template>
  <div class="wrapper">
      <div class="nav flex">
          <div class="nav-item" v-for="(item, index) in nav" :class="active===item.type&&'nav-active'" @click="navClick(item)" :key="index">
              {{item.name}}
          </div>
      </div>
      <template v-if="empty">
            <v-empty type="order" />
      </template>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text=""
        @load="onLoad" 
        class="goods-list">
          <!--订单列表-->
        <div class="goods-item" v-for="(item, index) in list" :key="index" @click="$router.push({path:'/my/orderListSMCS/orderDetails',query: {id: item.orderSn}})">
            <div class="state-box flex ai-c jc-sb">
                <div class="lb">下单时间：{{item.createTime}}</div>
                <div class="state" v-if="item.status==0">待支付</div>
                <div class="state" v-if="item.status==1">待发货</div>
                <div class="state" v-if="item.status==2">已发货</div>
                <div class="state" v-if="item.status==3">已完成</div>
                <div class="state" v-if="item.status==4">已关闭</div>
                <div class="state" v-if="item.status==5">无效订单</div>
            </div>
            <div class="goods-shop">
                <img src="/img/icon-shop.png" alt="">
                i团团云供应链
            </div>
            <div class="list">
                <div class="goods flex" v-for="(im, ix) in item.goodsList" :key="ix">
                    <!-- <img :src="im.goodsPic?im.goodsPic.split(',')[0]:''" alt=""> -->
                    <van-image class="goods-img" fit="cover" lazy-load :src="im.goodsPic?im.goodsPic.split(',')[0]:''" />
                    <div class="" style="width: calc(100% - 1.84rem)">
                        <div class="flex ai-bl jc-sb">
                            <div class="name line">{{im.goodsName}}</div>
                            <div class="price">
                                ¥{{im.goodsPrice}}
                            </div>
                        </div>
                        <div class="flex ai-c jc-sb" style="margin-top:.1rem">
                            <div class="classification line">{{im.specifications}}</div>
                            <div class="nb">x{{im.goodsQuantity}}</div>
                        </div>
                        <div class="refund-state" v-if="im.refundStatus!=0&&im.refundStatus!=4&&im.refundStatus!=5">
                            售后中
                        </div>
                        <div class="refund-state" v-if="im.refundStatus==4">
                            已退款
                        </div>
                        <div class="refund-state" v-if="im.refundStatus==5">
                            售后失败
                        </div>
                        <!-- <div class="fw">7天无理由退换</div> -->
                    </div>
                </div>
            </div>
            <div class="picker-box flex ai-c jc-sb" v-if="item.couponAmount>0">
                <div class="picker-name flex ai-c"><img src="/img/icon-yhq.png" alt=""> 优惠券</div>
                <div class="flex ai-c">
                    <div class="txt" style="color:#F35E3A">-￥{{item.couponAmount}}</div>
                </div>
            </div>
            <div class="subtotal flex ai-c jc-fd" :style="item.couponAmount<=0&&'margin-top:.3rem'">
                <div class="subtotal-zl">共{{item.quantity}}件商品</div>
                <div class="subtotal-lb">{{item.status==0||item.status==4||item.status==5?'需付款：':'实付款：'}}</div>
                <div class="subtotal-price">¥{{item.payAmount}}</div>
            </div>
            <div class="btn-box flex ai-c jc-fd">
                
                <template v-if="item.status==0">
                    <div class="btn" @click.stop="cancelClick(item.orderSn)">取消订单</div>
                    <div class="btn" @click.stop="payOrder(item.orderSn)">确认付款</div>
                </template>
                <template v-if="item.status==2||item.status==3">
                    <div class="btn" style="border: 0.02rem solid #61626a;color: #61626a;" @click.stop="$router.push({path:'/my/orderListSMCS/orderDetails/logistics',query: {id: item.orderSn}})">查看物流</div>
                </template>
                <template v-if="item.status==2">
                    <!-- <div class="btn" @click.stop="$router.push({path:'/my/orderListSMCS/orderDetails/logistics',query: {id: item.orderSn}})">查看物流</div> -->
                    <div v-show="!item.isRefund" class="btn" @click.stop="confirm(item.orderSn)">确认收货</div>
                </template>
            </div>
        </div>
      </van-list>
      <v-loveGoodsList />
      <div class="kong"></div>
      <div class="go-home flex ai-c jc-c">
          <div class="btn" @click="$router.push('/home')">返回商城</div>
      </div>
  </div>
</template>

<script>
import loveGoodsList from '@/components/loveGoodsList'
import { orderPage, cancelOrder, unifiedOrder, confirmOrder, orderPay } from '../../../api'
export default {
  components:{
      'v-loveGoodsList': loveGoodsList
  },
  props:{},
  data(){
    return {
        nav: [{
            name: '全部',
            type: ''
        },{
            name: '待支付',
            type: '0'
        },{
            name: '待发货',
            type: '1'
        },{
            name: '待收货',
            type: '2'
        },
        // {
        //     name: '售后',
        //     type: '10'
        // }
        ],
        active: '',
        current: 1,
        list: [],
        loading: false,
        finished: false,
        empty: true
    }
  },
  watch:{},
  computed:{},
  methods:{
      navClick(item) {
          document.body.scrollTop = document.documentElement.scrollTop = 0;
          this.active = item.type
          this.current = 1
          this.list = []
          this.empty = true
          this.finished = true
          this.orderPage()
      },
      //确认收货
      confirm (orderSn) {
        this.$dialog.confirm({
            title: '提示',
            message: '确认收货',
        })
        .then(() => {
            // on confirm
            this.$toast.loading({
              duration: 0,
              forbidClick: true
            })
            confirmOrder(orderSn).then(res=>{
                this.$toast.success({
                    message: '确认收货成功',
                    duration: 1500,
                    forbidClick: true
                })
                setTimeout(()=>{
                    this.current = 1
                    this.list = []
                    this.orderPage()
                },1500)
            }).catch(()=>{
              this.$toast.clear()
            })
        })
      },
      //获取支付订单号
      payOrder (data) {
          unifiedOrder(data).then(res=>{
                var ua = navigator.userAgent.toLowerCase();
                if (ua.match(/MicroMessenger/i) == "micromessenger") {
                    console.log('在微信端');
                    this.wxPay(res.data.data,data)
                } else if (ua.match(/xmsmk/i) == "xmsmk"){
                    this.pay(res.data.data,data)
                } else {
                    this.ixAppPay(res.data.data,data)
                }
              
          }).catch(res=>{
              this.$toast.clear()
          })
      },
      //i厦门app支付
      ixAppPay (orderNo,orderSn) {
        ixm.openPay((res)=>{
            // 成功回调
            if (res.result){
                this.$toast.success({
                    message: '支付成功',
                    duration: 1500,
                    forbidClick: true,
                })
                setTimeout(() => {
                    this.current = 1
                    this.finished = true
                    this.list = []
                    this.orderPage()
                }, 1500)
            } else {
                this.current = 1
                this.finished = true
                this.list = []
                this.orderPage()
            }
        }, {
            orderId: orderNo,  // 订单标识
            thirdSystemId: localStorage.getItem("payThirdSystemId"), // 第三方应用标识
        })
      },
      //微信支付
      wxPay(orderNo,orderSn) {
        location.href='https://smk.ixiamen.org.cn/smk/smk-unipay-transfer/#/?orderNo='+orderNo
      },
      //厦门市民卡app支付
      pay (orderNo,orderSn) {
          var that = this
          var ua = navigator.userAgent
          var preloadJs = function() {
            // Promise语法是ES6⽀持的，其他不⽀持的请⾃⾏转成ES5
            return new Promise((resolve, rejct) =>{
                if (ua.toLowerCase().indexOf("xmsmk") !== -1) {
                    if (window.UmsApi === undefined) {
                        document.addEventListener('OnUmsApiReady',
                        function() {
                            resolve(true)
                        },false)
                    } else {
                        resolve(true)
                    }
                } else {
                    // ⾮市⺠卡接⼊
                    reject('⾮市⺠卡接⼊')
                }
            })
          }
          function unifyPay(orderNumber) {
            UmsApi.page.processUnifyPay({orderNo: orderNumber}, function(data) {
                // data中respCode为：0000 表示成功，其他情况失败
                console.log("返回数据:"+JSON.stringify(data))
                that.orderPay(data.errCode,orderSn)
            },function(data){
                //失败
                console.log("返回数据:"+JSON.stringify(data))
            });
          }
          preloadJs().then(res=>{
            // 调⽤市⺠卡相关⽅法
            that.$toast.clear()
            unifyPay(orderNo)
          })
      },
      //取消订单
      cancelClick(orderSn) {
        this.$dialog.confirm({
            title: '提示',
            message: '确认取消该订单',
        })
        .then(() => {
            // on confirm
            this.$toast.loading({
              duration: 0,
              forbidClick: true
            })
            cancelOrder(orderSn).then(res=>{
                
                this.$toast.success({
                    message: '取消订单成功',
                    duration: 1500,
                    forbidClick: true
                })
                setTimeout(()=>{
                    this.current = 1
                    this.list = []
                    this.orderPage()
                },1500)
                
            }).catch(()=>{
              this.$toast.clear()
            })
        })
      },
      onLoad() {
          this.orderPage()
      },
      orderPage() {
          this.$toast.loading({
              duration: 0,
              forbidClick: true
          })
          orderPage({
              current: this.current,
              size: 20,
              status: this.active,
          }).then(res=>{
              var list = res.data.data.records
              this.current++
              for (var item of list) {
                  item.quantity = 0
                  item.isRefund = false
                  for (var im of item.goodsList) {
                    //判断是否属于售后单
                    if (im.refundStatus!=0&&im.refundStatus!=4&&im.refundStatus!=5) {
                        item.isRefund = true
                    }
                    item.quantity +=im.goodsQuantity
                    var specifications = []
                    im.goodsAttr = JSON.parse(im.goodsAttr)
                    for (var val of im.goodsAttr) {
                        specifications.push(val.v)
                    }
                    im.specifications = specifications.join('、')
                  }
                  
              }
              // 加载状态结束
              this.loading = false;
              if (list.length==0) {
                this.finished = true;
              } else {
                this.finished = false;  
              }
              this.list = this.list.concat(list)
              if (this.list.length==0) {
                    this.empty = true
                } else {
                    this.empty = false
              }
          }).finally(()=>{
              this.$toast.clear()
          })
      },
      orderPay(code,orderSn) {
        orderPay(orderSn).finally(()=>{
            if (code=='0000') {
                this.$toast.success({
                    message: '支付成功',
                    duration: 1500,
                    forbidClick: true,
                })
                setTimeout(() => {
                    this.current = 1
                    this.finished = true
                    this.list = []
                    this.orderPage()
                }, 1500)
            } else {
                this.current = 1
                this.finished = true
                this.list = []
                this.orderPage()
            }
        })
      }
  },
  created(){
      if (this.$route.query.type) {
          this.active = this.$route.query.type
      }
  },
  mounted(){}
}
</script>
<style scoped>
.wrapper{
    overflow: hidden;
}
.wrapper .nav{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: .8rem;
    background-color: #fff;
    z-index: 100;
}
.wrapper .nav .nav-item{
    position: relative;
    flex: 1;
    line-height: .8rem;
    text-align: center;
    font-size: .30rem;
    font-family: PingFang;
    font-weight: 500;
    color: #282828;
}
.wrapper .nav .nav-active{
    font-weight: bold;
    color: #F7532C;
}
.wrapper .nav .nav-active::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: calc(50% - .44rem);
    width: .88rem;
    height: .04rem;
    background: #F35E3A;
}
.goods-list {
    width: 100%;
    margin-top: 1rem;
    
}
.state-box{
    width: 100%;
    height: .73rem;
    border-bottom: 1px solid rgba(244, 244, 244, 1);
}
.state-box .lb{
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #AAAAAA;
    margin-left: .28rem;
}
.state-box .state{
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #F35E3A;
    margin-right: .28rem;
}
.goods-item{
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    margin-bottom: .2rem;
    padding-bottom: .4rem;
}
.goods-item .goods-shop{
    font-size: .26rem;
    font-family: PingFang;
    font-weight: bold;
    color: #61626A;
    margin-left: .3rem;
    margin-top: .3rem;
}
.goods-item .goods-shop img{
    width: .22rem;
    height: .2rem;
    margin-right: .1rem;
   
}
.list{
    margin-top: .1rem;
}
.list .goods{
    width: 100%;
    box-sizing: border-box;
    padding: 0.3rem .3rem;
}
.list .goods .goods-img{
    width: 1.54rem;
    height: 1.54rem;
    margin-right: .3rem;
    object-fit: cover;
}
.list .goods .name{
    /* width: auto; */
    /* width: 3.90rem; */
    font-size: .30rem;
    font-family: PingFang;
    font-weight: 500;
    color: #61626A;
    margin-right: .16rem;
}
.list .goods .price{
    white-space: nowrap;
    font-size: .28rem;
    font-family: PingFang;
    font-weight: 500;
    color: #61626A;
}
.list .goods .price font{
    font-size: .24rem;
}
.list .goods .classification{
    max-width: 3.5rem;
    height: .38rem;
    padding: 0 .05rem;
    line-height: .38rem;
    box-sizing: border-box;
    background: #F4F4F4;
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #AAAAAA;
}
.list .goods .nb{
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #AAAAAA;
}
.list .goods .fw{
    width: fit-content;
    height: .28rem;
    line-height: .28rem;
    padding: 0 0.05rem;
    background: #F9EFED;
    font-size: .20rem;
    font-family: PingFang;
    font-weight: 500;
    color: #F6593F;
    margin-top: .4rem;
}
.subtotal{
    /* margin-top: .5rem; */
}
.subtotal-zl{
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #AAAAAA;
}
.subtotal-lb{
    font-size: .30rem;
    font-family: PingFang;
    font-weight: 500;
    color: #61626A;
    margin-left: .15rem;
}
.subtotal-price{
    font-size: .30rem;
    font-family: PingFang;
    font-weight: 800;
    color: rgba(40, 40, 40, 1);
    margin-right: .38rem;
}
.subtotal-price font{
    font-size: .28rem;
}
.btn-box {
    margin-top: .35rem;
}
.btn-box .btn{
    width: 1.7rem;
    height: 0.70rem;
    line-height: .7rem;
    text-align: center;
    background: #FDFEFF;
    border: .02rem solid #61626A;
    border-radius: .35rem;
    margin-right: .27rem;
    font-size: .28rem;
    font-family: PingFang;
    font-weight: 500;
    color: #61626A;
}
.btn-box .btn:nth-last-child(1){
    color: #F35E3A;
    border-color: #F35E3A;
}
.kong{
    width: 100%;
    height: 2rem;
}
.go-home{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.4rem;
    background-color: #fff;
    padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
    padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
}
.go-home .btn{
    width: 6.90rem;
    height: .90rem;
    background: #F35E3A;
    border-radius: .45rem;
    font-size: .36rem;
    font-family: PingFang;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    line-height: .9rem;
}
.picker-box {
    width: 100%;
    padding: .24rem .2rem .24rem .3rem;
    box-sizing: border-box;
}
.picker-box .picker-name{
    font-size: .28rem;
    font-family: PingFang;
    font-weight: 500;
    color: #61626A;
}
.picker-box .picker-name img{
    width: .26rem;
    height: .35rem;
    margin-right: .1rem;
}
.picker-box .txt{
    width: 5rem;
    text-align: right;
    font-size: .28rem;
    font-family: PingFang;
    font-weight: 500;
    color: #61626A;
    margin-right: .1rem;
}

.refund-state{
    text-align: right;
    margin-top: .2rem;
    font-size: .28rem;
    color: #F35E3A;
    font-weight: bold;
}
</style>